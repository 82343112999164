import logoIcon from "../../../assets/icons/header-logo-ic.svg";
import styles from "./Logo.module.css";

/**
 * Logo 컴포넌트
 * 
 * - 로고 이미지를 렌더링하는 컴포넌트
 * - 로고의 가로(width)와 세로(height) 크기를 조정하여 유연하게 재사용
 * 
 * @param {number} width - 로고 이미지의 너비
 * @param {number} height - 로고 이미지의 높이
 * 
 * @returns {JSX.Element} 로고 컴포넌트 JSX
 */
const Logo = ({width,height}) => {

    return (
        <div>
            <img src={logoIcon} alt="logo" width={width} height={height} className={styles.logo} ></img>
        </div>
    )
}

export default Logo;
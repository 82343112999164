import { NavLink, useLocation } from "react-router-dom";
import Dropdown from "./TopDropdown";
import styles from "./TopMenuItems.module.css";
import { useState, useEffect, useRef } from "react";
import useTopNavStore from "../../../../store/useTopNavStore"; // 모든 드롭다운을 끄기 위한 전역변수
import classNames from "classnames/bind";

// classNames 바인딩
const cx = classNames.bind(styles);

/** 
 * 메뉴 클래스 네임을 결정하는 유틸 함수
 */
const getClassNames = (isTopLevel, isActive) => ({
  buttonClass: cx({
    "menu-item__top": isTopLevel,                 // 최상단 메뉴
    "menu-item__top-button": isTopLevel,          // 최상단 메뉴의 버튼
    "menu-item__submenu": !isTopLevel,            // 서브 메뉴
    "menu-item__submenu-button": !isTopLevel,     // 서브 메뉴의 버튼
  }),
  linkClass: cx({
    "menu-item__top": isTopLevel,                 // 최상단 메뉴
    "menu-item__top-link": isTopLevel,            // 최상단 메뉴의 링크
    "menu-item__submenu": !isTopLevel,            // 서브 메뉴
    "menu-item__submenu-link": !isTopLevel,       // 서브 메뉴의 링크
  }),

  lineClass: cx({
    "menu-item__top--line": isTopLevel,                // 최상단 메뉴의 밑줄 애니메이션 기본 클래스
    "menu-item__top--line-on": isTopLevel && isActive, // 활성화 상태에서 밑줄 애니메이션 클래스
  }),
  arrowClass: cx({
    "menu-item__arrow--down": isTopLevel,          // 드롭다운 화살표
    "menu-item__arrow--right": !isTopLevel,        // 오른쪽 화살표 (드롭다운 닫힘 상태)
  }),
});

/**
 * MenuItems 컴포넌트
 * 
 * - 네비게이션 메뉴 항목을 렌더링하는 컴포넌트
 * - 서브메뉴(드롭다운)를 포함하여 메뉴 계층 구조를 처리
 * - 현재 활성화된 메뉴를 강조
 * - Zustand 전역 상태 관리 라이브러리를 사용해서 링크를 클릭하면 전체 드롭다운 메뉴 OFF
 * 
 * @param {Object} props - 컴포넌트에 전달되는 속성
 * @param {Object} props.items - 메뉴 항목 데이터
 * @param {number} props.depthLevel - 현재 메뉴의 깊이 (0부터 시작)
 * 
 * @returns {JSX.Element} 렌더링된 메뉴 항목 JSX
 */
const MenuItems = ({ items, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);
  const { isMenuOpen, closeAllMenu, openAllMenu } = useTopNavStore();

  // 현재 활성화된 링크 하이라이트 강조
  const location = useLocation();
  const isActive = items.url
    ? location.pathname === items.url || location.pathname.startsWith(items.url)
    : false;

  const { buttonClass, linkClass, arrowClass, lineClass } = getClassNames(
    depthLevel === 0,
    isActive
  );


  let ref = useRef();

  // 메뉴요소 이외의 것을 클릭할경우 메뉴요소를 OFF로 유도하는 useEffect 함수
  useEffect(() => {
    const handler = (event) => {
      // 드롭다운이 열려있고, 터치된 요소가 현재 메뉴 항목의 외부에있는경우
      // 현재 메뉴항목을 off 
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {

      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  // navLink버튼을 클릭하게 된다면
  const navLinkClickHandler = () => {
    // 전역변수를 false로 설정한다
    closeAllMenu();

    // 추후 아래에있는 useMenuOpen변수에 의존하는 useEffect 함수에의해
    // 지역변수들의 DrdopDown 변수들 모두 false로 변경이된다.
  };

  // 전역변수인 isMenuOpen이 false라면
  // 지역변수인 dropDown변수가 false로변환(=드롭다운이 OFF가된다.)
  useEffect(() => {
    if (!isMenuOpen) {
      setDropdown(false);
    }
  }, [isMenuOpen]);

  // 드롭다운이 켜지게되면
  // 전역변수인 isMenuOpen이 true로 설정된다
  const menuOpenOnClickHandler = () => {
    openAllMenu();

    // 해당 드롭다운버튼을 누르게되면
    // ON일경우 OFF
    // OFF일경우 ON으로 변환이됨.
    setDropdown((prev) => !prev);
  };

  return (
    <li ref={ref} className={`${styles["menu-item"]} `}>
      {items.submenu ? (
        <>
          <button
            type="button"
            aria-expanded={dropdown ? "true" : "false"}
            aria-haspopup="menu"
            onClick={menuOpenOnClickHandler}
            className={buttonClass}
          >
            {items.title}
            <span className={arrowClass} />
            <span className={cx(lineClass)} />
          </button>
          <Dropdown
            depthLevel={depthLevel + 1}
            dropdown={dropdown}
            submenus={items.submenu}
            navLinkClickHandler={navLinkClickHandler}
            setDropdown={setDropdown}
          />
        </>
      ) : (
        <NavLink
          to={items.url}
          className={linkClass}
          end={true}
          onClick={navLinkClickHandler}
        >
          {items.title}
          <span className={cx(lineClass)} />
        </NavLink>
      )}
    </li>
  );
};

export default MenuItems;

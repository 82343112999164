import styles from "./Header.module.css";
import TopNavBar from "../navigation/top-navigation/TopNavBar";
import Logo from "../navigation/Logo";
import { NavLink } from "react-router-dom";
import ToggleSideMenu from "../navigation/ToggleSideMenu";

/**
 * Header
 * 
 * - 웹사이트 상단에 고정되는 헤더 컴포넌트.
 * - 로고, 네비게이션 바, 모바일 환경용 햄버거 메뉴를 렌더링.
 * - 반응형 디자인으로 데스크톱과 모바일 환경에 따라 다르게 렌더링.
 * 
 * @returns {JSX.Element} - Header 컴포넌트
 */
const Header = () =>{
  return (
    <header className={styles["header"]}>
      <div className={styles.header__content}>
      
        {/* 로고 및 Nav 메뉴 렌더링 */}
         <div className={styles["header__left-group"]}>
           <NavLink to="/"><Logo/></NavLink>
           <TopNavBar/>
         </div>

        {/* 모바일 환경일 경우 사이드바를 Open할 수 있는 햄버거 메뉴 렌더링 */}
         <div className={styles["header__right-group"]}>
           <ToggleSideMenu/>
         </div>
      
      </div>
    </header>
  );
};

export default Header;

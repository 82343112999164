import styles from "./SidebarDropdown.module.css";
import { CSSTransition } from "react-transition-group"; // 드롭다운 애니메이션을 위한 CSSTransition 컴포넌트
import classNames from "classnames/bind";
import SideItems from "./SidebarItems";

// CSSTransition 컴포넌트 사용 중 findDOMNode 오류를 해결하기 위해 nodeRef와 useRef를 사용
import { useRef } from "react";

const cx = classNames.bind(styles);

/**
 * SidebarDropdown 컴포넌트
 * 
 * - 서브메뉴를 렌더링하며 드롭다운 열림/닫힘 상태를 관리
 * - depthLevel에 따라 스타일과 동작이 달라짐
 * - CSSTransition을 사용하여 드롭다운의 애니메이션 처리
 * - findDOMNode 경고를 해결하기 위해 nodeRef와 useRef 사용
 * 
 * @param {Array} submenus - 서브메뉴 데이터
 * @param {boolean} dropdown - 드롭다운 활성화 상태
 * @param {number} depthLevel - 메뉴 깊이 (1부터 시작)
 * @param {Function} navLinkClickHandler - 링크 클릭 시 실행되는 핸들러
 * @param {Function} setMenuOpen - 사이드바 상태 변경 함수
 * @param {boolean} menuOpen - 사이드바 열림 상태
 * 
 * @returns {JSX.Element} 렌더링된 드롭다운
 */
const SidebarDropdown = ({
  submenus,
  dropdown,
  depthLevel,
  navLinkClickHandler,
  setMenuOpen,
  menuOpen,
}) => {

  // 드롭다운의 DOM 요소를 참조하기 위한 useRef
  const nodeRef = useRef(null);

  // 드롭다운 클래스 이름 결정
  const dropdownClass = cx({
    "dropdown--on": dropdown,               // 활성화 상태 스타일
    "dropdown--off": !dropdown,             // 비활성화 상태 스타일
  });

  return (
    <CSSTransition
      in={dropdown} // 드롭다운 활성화 상태
      timeout={300} // 애니메이션 지속 시간
      classNames={{
        enter: styles["dropdown-enter"],              // 드롭다운 열림 시작 클래스
        enterActive: styles["dropdown-enter-active"], // 드롭다운 열림 중 클래스
        exit: styles["dropdown-exit"],                // 드롭다운 닫힘 클래스
        exitActive: styles["dropdown-exit-active"],   // 드롭다운 닫힘 중 클래스
      }}
      unmountOnExit     // 드롭다운이 닫힐 때는 DOM에서 제거
      nodeRef={nodeRef} // DOM 요소 참조 전달
    >
      <ul ref={nodeRef} className={cx(dropdownClass)}>  
        {submenus.map((submenu, index) => ( 
          // 서브메뉴 유무에 따라 또다시 순회하여 SideItems 컴포넌트 출력
          <SideItems
            items={submenu} // 서브메뉴 데이터
            key={index}     // 고유 키
            depthLevel={depthLevel}  // 현재 메뉴 깊이 
            dropNavLinkClickHandler={navLinkClickHandler} // 링크 클릭 핸들러 
            setMenuOpen={setMenuOpen} // 사이드바 상태 변경 함수 
            menuOpen={menuOpen}       // 사이드바 상태 
          />
        ))}
      </ul>
    </CSSTransition>
  );
};

export default SidebarDropdown;

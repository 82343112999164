import styles from "./Footer.module.css";

/**
 * Footer
 * 
 * - 웹 페이지 하단에 위치하는 푸터 컴포넌트.
 * - 사이트 저작권 정보와 문의 메일 주소를 표시.
 * 
 * @returns {JSX.Element} - Footer 컴포넌트
 */
const Footer = () => {
    return (
        <footer className={styles["footer-container"]} >
            <div className={styles["footer-inner-container"]}>
                <div className={styles.content}>
                    <span>© www.wavesgacha All rights reserved.</span>
                    <span><b>문의메일</b> : devsurum@gmail.com</span>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
import styles from "./LazyLoader.module.css";
import lazyImage from "./lazy-screen.gif";

/**
 * LazyLoader
 * 
 * - Suspense 상태일 때 출력되는 스피너 이미지를 렌더링하는 컴포넌트.
 * 
 * @returns {JSX.Element} - 로딩 중임을 나타내는 스피너 이미지를 포함한 React 컴포넌트
 */
const LazyLoader = () => (
    <div className={styles["lazy-loader"]}>
        <img src={lazyImage} alt="로딩중"></img>
    </div>
  );
  
  export default LazyLoader;
import MenuItemsData from "../navItemsData"; // 네비 메뉴 항목 데이터 가져오기
import MenuItems from "./TopMenuItems";
import styles from "./TopNavBar.module.css";

/**
 * NavBar 컴포넌트
 * 
 * - 상단 네비게이션 바를 렌더링
 * - MenuItemsData 데이터를 기반으로 메뉴 목록을 생성
 *
 * @returns {JSX.Element} 네비게이션 바 컴포넌트
 */
const NavBar = () => {
  // 메뉴의 깊이를 나타내는 변수, 0부터 시작
  const depthLevel = 0;

  return (
    <nav className={styles.navbar}>
      {/* 네비게이션 메뉴 목록*/}
      <ul className={styles.navbar__menus}>
        {/* 메뉴 항목 데이터를 순회하여 MenuItems 컴포넌트를 생성 */}
        {MenuItemsData.map((menu, index) => {
          return (
            < MenuItems
              items={menu}            // 각 메뉴의 항목 데이터
              key={index}             // 고유키 설정
              depthLevel={depthLevel} // 메뉴의 깊이 전달
            />
          );
        })} 
      </ul>
    </nav>
  );
};

export default NavBar;

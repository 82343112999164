import { useEffect } from "react";
import styles from "./TopDropdown.module.css";
import MenuItems from "./TopMenuItems";
import useTopNavStore from "../../../../store/useTopNavStore";

/**
 * Dropdown 컴포넌트
 * 
 * - 서브메뉴를 렌더링하며 드롭다운의 열림/닫힘 상태를 관리
 * - 전역 상태(Zustand) isMenuOpen과 연동되어 지속적으로 추적하여(useEffect) usMenuOpen이 false면 드롭다운을 OFF
 * 
 * @param {Array} submenus - 렌더링할 서브메뉴 리스트
 * @param {boolean} dropdown - 드롭다운 활성화 상태
 * @param {Function} setDropdown - 드롭다운 상태를 변경하는 함수
 * @param {number} depthLevel - 현재 드롭다운의 깊이 (0부터 시작)
 * @param {Function} navLinkClickHandler - 링크 클릭 시 실행되는 핸들러
 * 
 * @returns {JSX.Element} 드롭다운 컴포넌트
 */
const Dropdown = ({
  submenus,
  dropdown,
  setDropdown,
  depthLevel,
  navLinkClickHandler,
}) => {
  const { isMenuOpen } = useTopNavStore();

  
  const primaryDropdownClass = `${styles["dropdown--on"]} ${styles["dropdown__primary"]}`;      // 최상위 드롭다운 클래스 depth 1
  const secondaryDropdownClass = `${styles["dropdown--on"]} ${styles["dropdown__secondary"]}`;  // 하위 드롭다운 클래스 depth 2
  const dropdownClass =
    depthLevel > 1 ? secondaryDropdownClass : primaryDropdownClass;


  // isMenuOpen이 false라면 모든 드롭다운 박스가 OFF가 되어야함.
  useEffect(() => {
    if (!isMenuOpen) {
      setDropdown(false); 
    }
  }, [isMenuOpen, setDropdown]);

  return (
    <ul className={dropdown ? dropdownClass : `${styles["dropdown--off"]}`}>
      {submenus.map((submenu, index) => (
        // 서브메뉴를 순회하며 MenuItems 컴포넌트를 다시 생성(재귀)
        <MenuItems
          items={submenu}
          key={index}
          depthLevel={depthLevel}
          navLinkClickHandler={navLinkClickHandler}
          dropdown={dropdown}
        />
      ))}
    </ul>
  );
};

export default Dropdown;

import { NavLink } from "react-router-dom";
import styles from "./Sidebar.module.css";
import { useRef, useEffect } from "react";
import Logo from "../Logo";
import MenuItemsData from "../navItemsData";
import SideItems from "./SidebarItems";

/**
 * Sidebar 컴포넌트
 *
 * - 햄버거 메뉴에 의존되어 열리고 닫히는 사이드바를 렌더링
 * - MenuItemsData 데이터를 기반으로 메뉴 목록을 생성
 * - 외부 클릭 감지를 통해 사이드바를 닫는 기능 포함
 *
 * @param {boolean} menuOpen - 사이드바 열림 상태
 * @param {Function} setMenuOpen - 사이드바 상태를 변경하는 함수
 *
 * @returns {JSX.Element} 사이드바 컴포넌트
 */
const Sidebar = ({ menuOpen, setMenuOpen }) => {
  // sidebar(nav태그)에 대한 ref변수
  let sideBarRef = useRef(null);

  // 메뉴의 깊이를 나타내는 변수, 0부터 시작
  const depthLevel = 0;

  useEffect(() => {
    // 만약 SideBar 메뉴가 열리지 않았다면 리스너 등록을 하지 않는다. (최적화)
    if (!menuOpen) {
      return;
    }

    // ref.current(사이드바 영역)을 제외한 곳을 클릭하게 되면 닫히게하는 핸들러
    const handler = (event) => {
      if (sideBarRef.current && !sideBarRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [menuOpen, setMenuOpen]);

  return (
    <nav
      ref={sideBarRef} // 사이드바 DOM 요소 참조
      className={
        menuOpen
          ? `${styles.sidebar} ${styles["sidebar--open"]}` // 열림 상태 클래스(동적인효과)
          : styles.sidebar // 닫힘 상태 클래스
      }
    >
      {/* 사이드바 로고 */}
      <div className={styles.sidebar__logo}>
        <NavLink to="/">
          <Logo width="150" height="50" />
        </NavLink>
      </div>

      {/* 사이드바 메뉴 리스트 */}
      <ul className={styles.sidebar__menus}>
        {MenuItemsData.map((menu, index) => {
          return (
            <SideItems
              items={menu}               // 각 메뉴의 항목 데이터
              key={index}                // 고유 키 설정
              depthLevel={depthLevel}    // 메뉴 깊이 전달
              setMenuOpen={setMenuOpen}  // 사이드바 상태 변경 함수 전달
              menuOpen={menuOpen}        // 사이드바 열림 상태 전달
            />
          );
        })}
      </ul>
    </nav>
  );
};

export default Sidebar;

import { Outlet } from "react-router-dom";
import Header from "../components/layout/header/Header";
import Footer from "../components/layout/footer/Footer";
import styles from "./Root.module.css";

// RootLayout 컴포넌트 정의 (모든 페이지의 공통 레이아웃을 담당)
function RootLayout() {
  return (
    // 전체 레이아웃 컨테이너
    <div className={styles["root-layout"]}>
      
      {/* 헤더 렌더링 */}
      <Header />

      {/* 메인 콘텐츠 영역 */}
      <main className={styles["main-content"]}>
        {/* 현재 라우트에 해당하는 자식 컴포넌트 렌더링 */}
        <Outlet />
      </main>

      {/* 푸터 렌더링 */}
      <Footer />

    </div>
  );
}

export default RootLayout;

import SideBar from "./side-navigation/Sidebar";
import styles from "./ToggleSideMenu.module.css";
import { useState } from "react";
import ReactDOM from "react-dom";
import HamburgerMenuIcon from "../../../assets/icons/header-menu-ic.svg";

/**
 * ToggleSideMenu
 *
 * - 햄버거 메뉴 버튼을 통해 사이드바를 열고 닫는 기능을 제공하는 컴포넌트
 * - 사이드바와 배경 오버레이를 React Portal을 이용해 렌더링
 *
 * @returns {JSX.Element} 토글 사이드 메뉴 컴포넌트
 */
const ToggleSideMenu = () => {
  // 사이드 메뉴의 열림 상태를 관리하는 상태 변수
  const [menuOpen, setMenuOpen] = useState(false);

  /**
   * 메뉴 상태를 토글하는 함수
   * - 버튼을 클릭하면 현재 상태를 반대로 변경
   */
  const toggleMenu = () => {
    setMenuOpen((prevState) => !prevState);
  };

  /**
   * 메뉴를 닫는 함수
   * - 오버레이 영역을 클릭하면 사이드바를 닫음
   */
  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <span>
        {/* 햄버거 메뉴 버튼 */}
        {/* 클릭 시 사이드바 토글 */}
        <button
          className={styles["header__right-group__button"]}
          onClick={toggleMenu}
        >
          <img src={HamburgerMenuIcon} aria-label="메뉴 열기"></img>
        </button>
      </span>
      {/* React Portal을 이용한 오버레이와 사이드바 렌더링 */}
      {ReactDOM.createPortal(
        <>
          {/* 메뉴가 열려 있을 때만 오버레이를 렌더링 */}
          {menuOpen && (
            <div
              className={styles["header__right-group__overlay"]}
              onClick={closeMenu}
            ></div>
          )}
          {/* SideBar 컴포넌트에 상태 전달 */}
          <SideBar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        </>,
        document.getElementById("sidebar-portal")
      )}
    </>
  );
};

export default ToggleSideMenu;

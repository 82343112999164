import { Link, useRouteError } from "react-router-dom";
import styles from "./ErrorPage.module.css";

// 잘못된 라우터 이동시 에러 페이지 출력
const ErrorPage = () => {
  const error = useRouteError();
  const errorMessage =
    error.status === 404
      ? "404 | Page Not Found"
      : "예상치 못한 오류가 발생했습니다.";

  return (
    <div className={styles["error-page"]}>
      <p className={styles["error-page__message"]}>{errorMessage}</p>
      <Link to="/" className={styles["error-page__link"]}>
        홈으로 이동
      </Link>
    </div>
  );
};

export default ErrorPage;

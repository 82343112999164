import { useState, useEffect } from "react";
import SidebarDropdown from "./SidebarDropdown";
import { NavLink } from "react-router-dom";
import styles from "./SidebarItems.module.css";
import classNames from "classnames/bind";

// css조건이 다소 복잡할경우 classNames 라이브러리 사용하자.
const cx = classNames.bind(styles);

/**
 * 드롭다운 클래스 결정 함수
 * - 메뉴의 깊이(isTopLevel)에 따라 적절한 클래스 이름 반환
 */
const getClassNames = (isTopLevel, dropdown) => ({
  buttonClass: cx({
    sidebar__top: isTopLevel,
    "sidebar__top--active": isTopLevel && dropdown,
    "sidebar__top-button": isTopLevel,

    sidebar__submenu: !isTopLevel,
    "sidebar__submenu-button": !isTopLevel,
    "sidebar__submenu--active": !isTopLevel && dropdown,
  }),
  navlinkClass: cx({
    sidebar__top: isTopLevel,
    "sidebar__top-link": isTopLevel,

    sidebar__submenu: !isTopLevel,
    "sidebar__submenu-link": !isTopLevel,
  }),
});

/**
 * SidebarItems 컴포넌트
 *
 * - 사이드바 메뉴 항목을 렌더링하며 서브메뉴(드롭다운)를 관리
 * - 메뉴의 깊이(depthLevel)에 따라 스타일과 동작이 달라짐
 * - 드롭다운 상태를 관리하고, Nav 메뉴 클릭 시 사이드바를 닫는 기능 포함
 *
 * @param {Object} items - 메뉴 항목 데이터
 * @param {number} depthLevel - 현재 메뉴의 깊이 (0부터 시작)
 * @param {boolean} menuOpen - 사이드바 열림 상태
 * @param {Function} setMenuOpen - 사이드바 상태를 변경하는 함수
 *
 * @returns {JSX.Element} 렌더링된 사이드바 항목
 */
const SidebarItems = ({ items, depthLevel, menuOpen, setMenuOpen }) => {
  // 개별 드롭다운 변수
  const [dropdown, setDropdown] = useState(false);

  // 클래스 계산
  const { buttonClass, navlinkClass } = getClassNames(
    depthLevel === 0,
    dropdown
  );

  // 드롭다운 화살표 클래스
  const arrowClass = cx({
    button__arrow: true,
    "button__arrow--down": dropdown,
    "button__arrow--right": !dropdown,
  });

  // 링크 클릭 핸들러
  const navLinkClickHandler = () => {
    setMenuOpen(false);
  };

  // 드롭다운 토글 핸들러
  const toggleDropdown = () => {
    setDropdown((prevState) => !prevState);
  };

  // menuOpen 상태가 false일경우 개별 드롭다운 메뉴를 닫음
  useEffect(() => {
    if (!menuOpen) {
      setDropdown(false); 
    }
  }, [menuOpen]);

  return (
    <li className={cx("sidebar__menu-item")}>
      {/* subMenu가 있을경우(=하위 메뉴가 있는경우) */}
      {items.submenu ? (
        <>
          <button
            type="button"
            aria-expanded={dropdown ? "true" : "false"}
            aria-haspopup="menu"
            onClick={toggleDropdown}
            className={buttonClass}
          >
            <span className={cx("button_text")}>{items.title}</span>
            <span className={cx(arrowClass)} />
          </button>
          <SidebarDropdown
            // 드롭다운을 한번씩 거칠때마다 depthLevel을 +1늘림
            depthLevel={depthLevel + 1}
            dropdown={dropdown}
            submenus={items.submenu}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
          />
        </>
      ) : (
        // subMenu가 없을경우(=하위 메뉴가 없는경우)
        <>
          <NavLink
            to={items.url}
            end={true}
            className={navlinkClass}
            onClick={navLinkClickHandler}
          >
            {items.title}
          </NavLink>
        </>
      )}
    </li>
  );
};

export default SidebarItems;
